@mixin banner ($image: "foo.jpg", $xpos: center, $ypos: center) {
	@media screen and (min-width: $on-laptop) {
	    width: 100%;
	    background-image: url($image);
	    height: 300px;
	    //background-size: 100% 30%;
	    //background-color: black;
	    background-position: $xpos $ypos;
	    //border-bottom: 40px solid $grey-color-light;
	    //outline: 1px solid black;
	    margin-bottom: 40px;
  	}
}

#banner-meta {
  	@include banner("/blog/assets/img/meta.jpg");
}
	
#banner-woodworking {
	@include banner("/blog/assets/img/tool_chest_2474.jpg",center,-380px);
}

#banner-photography {
	//@include banner("/blog/assets/img/IMG_9245.jpg",-200px,-260px);
	//@include banner("/blog/assets/img/5D3_3149.jpg",-410px,-300px);
	@include banner("/blog/assets/img/5D3_5298.jpg",0px,0px);
}

#banner-electronics {
	@include banner("/blog/assets/img/5D3_5227.jpg",center,-150px);
}

#banner-others {
	@include banner("/blog/assets/img/5D3_1817.jpg",-200px,-160px);
	//@include banner("/blog/assets/img/5D3_1907.jpg",-450px,-650px);
}

#banner-software {
    @include banner("/blog/assets/img/software.png",5px,0px);
}
