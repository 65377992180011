/**
 * Site header
 */
.site-header {
    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
    float: right;
    line-height: 56px;
    z-index: 2;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: calc(#{$spacing-unit} / 2);
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: calc(#{$spacing-unit} / 2);
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: calc(-1 * #{$spacing-unit} / 2);	// srsly?!
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: calc(#{$spacing-unit} / 2);
    padding-left: calc(#{$spacing-unit} / 2);
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 24px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-categories {
    //font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: calc(#{$spacing-unit} / 2);
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

.post-navigation {
  font-size: $small-font-size;
  display: block;
  width: auto;
  overflow: hidden;
}

.post-navigation a {
  display: block;
  width: 50%;
  float: left;
  margin: 1em 0;
}

.post-navigation .next {
  text-align: right;
}

.footnotes {
	margin: 10mm 0;
    border-top:1px solid $grey-color-light;
    //border-left:1px solid $grey-color-light;
}

.post-item {
	border-top:1px solid $grey-color-light;
	//border-bottom:1px solid $grey-color-light;
}

.divgrid-div {
    text-align: left;
    
    .divgrid-box {
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    align-content: space-between;
	}

    .divgrid-caption {
        //font-style: italic;
        font-size: $small-font-size;
        color: $grey-color-dark;
        margin-top: calc(#{$spacing-unit} / 6);
        margin-left: calc(#{$spacing-unit} / 6);
    }
}


/* Pagination */
ul.pager { 
	text-align: center;
	list-style: none;
	padding-top: 10px;
	
	li {
		display: inline-block;
		padding: 5px 10px 5px 10px;
		border: 1px solid black;
		min-width: 40px;
		color: $brand-color;
	}

	a {
		margin: 0;
		display: inline-block;
	}

	.selected { 
		background-color: $brand-color; 
		a {
			color: $background-color;
		}
	}
}




/* The sidebar menu */
.sidenav {
	$sidenav-width: 200px;
    height: 90%;
    width: $sidenav-width;
    position: fixed;
    z-index: 1;
    top: 62px;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 0px;
    padding-left: 30px;
    
    .nav-header {
        font-size: 30px;
    }
    
    .menu-icon {
        display: none;
    }
    
    .page-link {
        text-decoration: none;
        font-size: 16px;
        //color: #818181;
        //display: block;
    	display: list-item;
    	list-style-type: circle;
    	list-style-position: inside;
        
        /*
        //padding: 6px 8px 6px 16px;
        &:not(:last-child) {
         	margin-bottom: 16px;
    	}*/
    }
    
    @include media-query($content-width+2*($sidenav-width+20px)) {
        width: auto;
        height: auto;
        overflow-y: hidden;
        position: absolute;
        padding-top: 0px;
	    padding-left: 0px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;
		left: auto;
        top: 90px;
        right: calc(#{$spacing-unit} / 2);

        .nav-header {
            display: none;
        }
        
        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            padding-left: 0px;
            text-align: center;
            
            > svg {
                width: 18px;
                height: 15px;
                
                path {
                    fill: $grey-color-dark;
                }
            }
        }
        
        .trigger {
            clear: both;
            display: none;
        }
        
        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
            padding-right: 10px;
            
            height: 400px;
	        overflow-y: auto;
        }
        
        .page-link {
        	text-align: right;
            display: block;
            font-size: 16px;
            padding: 2px 10px;
            
            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}

/* 
.sidenav a:hover {
  color: #f1f1f1;
}*/


/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #ccc;
}

/* Shared tooltip text */
@mixin basic-tooltip {
  visibility: hidden;

  display: inline-block;
  width: max-content;
  min-width: 100px;
  max-width: 400px;
  text-align: center;
  color: $footnote-color-text;
  font-size: 15px;
  padding: 5px 5px;
  border-radius: 6px;
  background-color: $footnote-color-background;
  border: 1px solid $footnote-color-border;
  box-shadow:0 1px 4px rgba(0,0,0,.3);

  position: absolute;
  z-index: 1;
  bottom: 125%;
  //left: 50%;
  margin-left: -50px;

  opacity: 0;
  transition: opacity 0.3s;
}

/* Simple tooltip text */
.tooltip .tooltiptext {
  	@include basic-tooltip;
}

/* Footnote tooltip text */
.tooltip .tooltiptext-footnote {
	@include basic-tooltip;
	
	margin-left: -95px;
	text-align: left;
}

/* Tooltip arrow */
.tooltip *[class^="tooltiptext"]::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 100px;	// !!!
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: $footnote-color-border transparent transparent transparent;
}

/* Show the tooltip text */
.tooltip:hover *[class^="tooltiptext"] {
  visibility: visible;
  opacity: 1;
}

.data-table {
table {
  margin: 25px auto;
  border-collapse: collapse;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.10),
     0px 2px 4px rgba(0,0,0,0.05),
     0px 4px 4px rgba(0,0,0,0.05),
     0px 6px 4px rgba(0,0,0,0.05);
  tr {
     &:hover {
      background: #f4f4f4;
      
      td {
        color: $grey-color-dark;
      }
    }
  }
  th, td {
    padding: 5px 20px;
    border-collapse: collapse;
  }
  th {
    border-bottom: 1px solid #eee;
    background: $brand-color;
    color: #fff;
    //text-transform: uppercase;
  }
}
}

